<template>
  <div class="pack">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          包裹称重-手机版
        </div>

        <el-form :inline="true" id="el-form" ref="form" :model="form" class="demo-form-inline" @submit.native.prevent size="mini">
<!--          <el-form-item label="设备：" prop="packName">-->
<!--            <el-select v-model="form.packPhone" placeholder="全部" clearable>-->
<!--              <el-option v-for="item in packUserList" :key="item.status" :label="item.statusDesc"-->
<!--                         :value="item.status"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="快递单号" prop="mailNo">
            <el-input v-model="form.mailNo" @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item label="重量：KG" prop="packWeight">
            <el-input v-model="form.packWeight" @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button type="primary" @click="onSubmit" :disabled="isLoading">上传</el-button>
            <el-button @click="pushToWinPage">切换电脑版</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-form :inline="true" ref="queryform" :model="queryform" class="demo-form-inline" size="mini">
          <el-form-item label="打包组：" prop="packName">
            <el-select v-model="queryform.packPhone" placeholder="全部" clearable>
              <el-option v-for="item in packUserList" :key="item.status" :label="item.statusDesc"
                         :value="item.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="打包时间：">
            <el-date-picker
              v-model="queryform.packDateQuery"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button type="primary" @click="querySubmit" :disabled="isLoading">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="statistic">
        <el-row :gutter="20">
          <el-col :span="12" >
            <div>
              <el-statistic group-separator="," :value="dataTotal" title="总包裹数(单)："></el-statistic>
            </div>
          </el-col>
          <el-col :span="12" >
            <div>
              <el-statistic group-separator="," :value="totalWeight" title="总重量(kg)：">
              </el-statistic>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="mini">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <el-table-column prop="packWeight" label="重量：KG"></el-table-column>
          <el-table-column prop="packName" label="称重组"></el-table-column>
          <el-table-column prop="packPhone" label="称重员手机号"></el-table-column>
          <!-- 设置过滤器需要使用作用域插槽获取数据-->
          <el-table-column label="打包时间">
            <template slot-scope="scope">
              <span>{{ scope.row.packTime | dateFormate }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column label="操作" width="200" fixed="right">-->
<!--            <template slot-scope="{row}">-->
<!--              <el-button @click="clear(row)" type="danger" size="mini">删除</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->

        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

    </el-card>

  </div>
</template>

<script>
import {pack, findAllMailNoByPage, clearPackInfo,findPackTotalWeight} from '@/services/logistics'
import {getUserInfo} from '@/services/user'
import {getNowDate, dateFormate} from '@/utils/dateUtil'
import {findAllPackUser} from "@/services/status";
import Speech from 'speak-tts'
export default {
  name: 'phonePack',
  data() {
    return {
      userInfo: {},
      // 用于存储资源列表数据
      tableData: [],
      form: {
      },
      queryform: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      packUserList:[],
      totalWeight:0,

    }
  },
  methods: {
    pushToWinPage() {
      this.$router.push('/pack');
    },
    initInputDOM() {
      //获取id为el-form下的所有input 框
      const inputDoms = document.querySelectorAll(
        "#el-form .el-input__inner"
      );
      //遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute("data-index", index);
      });
      this.inputDoms = inputDoms;
    },
    //回车事件
    nextFocus(event) {
      const index = event.target.getAttribute("data-index");
      const nextIndex = parseInt(index) + 1;
      const length = this.inputDoms.length;
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus();
      } else {
        this.onSubmit()
        this.inputDoms[0].focus();
      }
    },
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.queryform.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.queryform.currentPage = 1
      this.loadAllMailNoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.queryform.currentPage = val
      this.loadAllMailNoByPage()
    },

    clear(row) {
      this.clearPackInfo(row)
    },
    async clearPackInfo(row) {
      // 清除打包记录
      const {data} = await clearPackInfo(row)
      if (200 !== data.code) {
        this.$message.error('清除打包记录失败')
      } else {
        this.$message.success(`清除打包记录 ${row.mailNo} 成功`)
      }
      await this.loadAllMailNoByPage()
    },
    // 上传
    async onSubmit() {
      await this.packOn()
      // 请求数据前，将请求的页数更新为1
      this.queryform.currentPage = 1
      await this.loadAllMailNoByPage()
    },

    // 添加筛选后的数据查询
    querySubmit() {
      this.loadAllMailNoByPage()
    },
    async loadAllMailNoByPage() {
      // 开始加载数据
      this.isLoading = true
      // 打包时间范围
      if (this.queryform && this.queryform.packDateQuery) {
        let packDateBegin = this.queryform.packDateQuery[0]
        let packDateEnd = this.queryform.packDateQuery[1]
        this.queryform.packDateBegin = packDateBegin
        this.queryform.packDateEnd = packDateEnd
      } else {
        this.queryform.packDateBegin = getNowDate() + ' ' + '00:00:00'
        this.queryform.packDateEnd = getNowDate() + ' ' + '23:59:59'
      }
      // 分页多条件查询快递单号信息
      const {data} = await findAllMailNoByPage(this.queryform)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      // 统计总重量
      const resp = await  findPackTotalWeight(this.queryform)
      if (resp.data.data) {
        this.totalWeight = resp.data.data
      } else {
        this.totalWeight = 0
      }



      this.isLoading = false
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },

    async loadAllPackUser() {
      // 查询打包员列表
      const {data} = await findAllPackUser()
      if (200 === data.code) {
        this.packUserList = data.data
      } else {
        this.$message.error('查询打包员列表失败')
      }
    },

    // 重置上传单号和重量
    resetMailNoAndWeightNull() {
      this.form.mailNo = ''
      this.form.packWeight = ''
    },
    async packOn() {
      if (!this.form || !this.form.packWeight|| !this.form.mailNo) {
        this.resetMailNoAndWeightNull()
        this.speak('单号及重量必须输入，请重新扫描')
        return this.$message.error('单号及重量必须输入，请重新扫描')
      }
      if (this.form.packWeight.trim().length>8) {
        this.resetMailNoAndWeightNull()
        this.speak('重量有问题，请重新扫描')
        return this.$message.error('重量有问题，请重新扫描')
      }
      if (this.form.mailNo.trim().length<=12) {
        this.resetMailNoAndWeightNull()
        this.speak('快递单号有问题，请重新扫描')
        return this.$message.error('快递单号有问题，请重新扫描')
      }
      const params = {
        packPhone: this.userInfo.phone,
        mailNo: this.form.mailNo,
        packName: this.userInfo.userName,
        packWeight: this.form.packWeight.trim()
      }
      const {data} = await pack(params)
      this.resetMailNoAndWeightNull()
      if (data.code === 200) {
        this.speak('称重成功')
        this.$message.success('称重成功')
      } else {
        this.speak(data.message)
        return this.$message.error(data.message)
      }
    },

    speechInit(){
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(()=>{
        console.log('语音播报初始化完成...')
      })
    },

    //语音播报
    speak(msg){
      this.speech.speak({text:msg}).then(()=>{
        console.log("播报完成...")
      })
    },
  },
  mounted() {
    this.speechInit();
  },
  created() {
    this.$nextTick(() => {
      this.initInputDOM();
    })
    this.loadAllPackUser()
    this.loadUserInfo()
    this.loadAllMailNoByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
.statistic{
  margin: 10px;
}
.btn-right {
  float: right;
}
</style>
